import { React, useState, useEffect } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Nftfram from "../../assets/images/nft-fram.png";
import { getLatestData,getLatestStage } from "../../redux/connectWallet/action";
import PushBackSubModal from "./pushBackSubModal";
import PushBackNoModal from "./pushBackNoModal";

const PushBackNFTModal = (props) => {
    const contractData = useSelector((state) => state.contract);
    const [nftSelected, setnftSelected] = useState([]);
    // const [noNft, setnoNft] = useState("")
    const [noNft, setnoNft] = useState("No NFT available for push Back")
    const [nftFlag, setNftFlag] = useState(false);
    let dispatch = useDispatch();

    const handleNftSelection = (data) => {

        if (nftSelected.length < 1) {
            let itemIndex = nftSelected.map((c) => c.nftId);
            itemIndex = itemIndex.indexOf(data.nftId);
            if (itemIndex < 0) {
                // debugger
                setnftSelected([...nftSelected, data]);
            }
            else {
                // debugger
                let prevData = [...nftSelected];
                prevData.splice(itemIndex, 1);
                setnftSelected([...prevData]);
            }
        }
        else {
            // toast.error('you cannot select more than one NFT to pushBack')
        }
    }
    const checkOfNftExist = () => {
        if(noNft == ""){
            setnoNft("No NFT available for push Back")       
        }  
        else {
            setnoNft("");
        } 
    }
    const handleNFT = () => {
        setnoNft("");
    }
    const handleNoNFT = () => {
        setnoNft("No NFT available for push Back")       
    }

    useEffect(() => {
        if(props.allUsers[Number(props.nftSelected[0]?.stage) - 1]){
            let temp = 0;
            props.allUsers[Number(props.nftSelected[0]?.stage) - 1].map((items) => {
              //   if(items){
                    if(
                      props.safeSides[Number(props.nftSelected[0]?.stage) - 2]?.safeTile !== undefined
                      && ((props.safeSides[Number(props.nftSelected[0]?.stage) - 2]?.safeTile >= 50
                          && items.lastJumpSide == true)
                          || (props.safeSides[Number(props.nftSelected[0]?.stage) - 2]?.safeTile < 50 
                          && items.lastJumpSide == false))
                      && contractData.signerWallet !== items.userWalletAddress
                      ){
                      temp = temp + 1
                    }
                  ;
              //   }
            })
          //   props.allUsers[Number(props.nftSelected[0]?.stage) - 1].length == temp ?
            temp === 0 ?
            setnoNft("No Nft available for pushback") : setnoNft("")
        }
      }, [props.allUsers[Number(props.nftSelected[0]?.stage) - 1]])
    
    const PushBackTranscation = async (data) => {
        if (contractData.contractInstance) {
            try {
                props.lodaer(true);
                let pushBack = await contractData.contractInstance.pushBack(props.nftSelected[0]?.playerId, data);
                let pushBackSuccess = await pushBack.wait();
                if (pushBackSuccess) {
                    setnftSelected([])
                    dispatch(getLatestStage(contractData.contractInstance))
                    dispatch(getLatestData(contractData?.contractInstance));
                    toast.success("You push Back the NFT successfully")
                    checkOfNftExist();
                    props.close();
                } else {
                    props.lodaer(false);
                    toast.error("Transaction Failed ");
                }
            } catch (error) {
                props.lodaer(false);
                toast.error(error.error?.data ? error.error?.data.message.split(":")[1] : "Your Transaction has been failed")
            }
        }
    }


    return (
        <>
            <Modal className="mynft-list restart nfts-data"
                show={props.show}
                cancel={props.close}
                size="lg"
                centered>
                <Modal.Header className="justify-content-center">
                    <Modal.Title>Players list</Modal.Title>
                    <button type="button" className="close" onClick={props.close}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body >
                    <div className="bulk-nft-list">

                        {props.allUsers[Number(props.nftSelected[0]?.stage) - 1] !== undefined
                            && props.allUsers[Number(props.nftSelected[0]?.stage) - 1].map((data, key) => {

                                if (
                                    props.safeSides[Number(props.nftSelected[0]?.stage) - 2]?.safeTile !== undefined
                                    && ((props.safeSides[Number(props.nftSelected[0]?.stage) - 2]?.safeTile >= 50
                                        && data.lastJumpSide == true)
                                        || (props.safeSides[Number(props.nftSelected[0]?.stage) - 2]?.safeTile < 50 
                                        && data.lastJumpSide == false))
                                    && contractData.signerWallet !== data.userWalletAddress
                                    ) {
                                                        return (

                                                    <div className={`item ${nftSelected
                                                        .map((c) => c.nftId)
                                                        .indexOf(data.nftId) < 0
                                                        ? ""
                                                        : "active"
                                                        }`}>
                                                            {/* <PushBackSubModal handleNFT={handleNFT} /> */}
                                                        <div
                                                            key={key}
                                                            className={`nft-content-wrapper d-flex align-items-center ${data.stageStatus == false ||
                                                                data.isDrop === false
                                                                ? "game-over"
                                                                : `nft-item `
                                                                }`}
                                                            onClick={() => handleNftSelection(data)}
                                                        >
                                                            <div className="nft-img">
                                                                <Image
                                                                    src={
                                                                        data.metaData.imageUrl
                                                                            ? data.metaData.imageUrl
                                                                            : Nftfram
                                                                    }
                                                                    fluid
                                                                />
                                                            </div>
                                                            <div className="nft-content">
                                                                <h3>NFT ID : {Number(data.nftId)}</h3>
                                                                {/* <h3>Series Type: {data.nftSeriestype}</h3> */}
                                                                <h3>Stage : {Number(data.stage)} </h3>
                                                            </div>
                                                        </div>
                                                        <button
                                                            className="restart"
                                                          onClick={() =>
                                                            PushBackTranscation(
                                                                  data.playerId
                                                              )
                                                          }
                                                        >
                                                            Push Back
                                                        </button>
                                                    </div>
                                                )
                                            }
                                          
                                        })}

                        <div className="nft-label fail-nft" style={{ color: 'white' }}>
                            <h2>

                                {""} {noNft}{" "}

                            </h2>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default PushBackNFTModal